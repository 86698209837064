@font-face {
  font-family: 'Space Mono';
  src: url('./fonts/SpaceMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Space Mono';
  src: url('./fonts/SpaceMono-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: 'Space Mono', monospace;
  margin: 0;
  padding: 0;
  background: #000;
  color: #fff;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: #e0e0e0;
  font-weight: bold;
}

/* Responsive text sizes */
@media (max-width: 640px) {
  h1 {
    font-size: 1.875rem !important;
  }
  h2 {
    font-size: 1.25rem !important;
  }
  p {
    font-size: 0.875rem !important;
  }
}

/* Carousel container */
.carousel-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 1rem 0;
}

.scroll-container {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

/* Card container responsiveness */
.card-container {
  flex: 0 0 auto;
  width: calc(100% - 2rem);
  max-width: 300px;
  transition: transform 0.3s ease;
}

/* Responsive card sizes */
@media (min-width: 640px) {
  .card-container {
    width: calc(50% - 1rem);
  }
}

@media (min-width: 768px) {
  .card-container {
    width: calc(33.333% - 1rem);
  }
}

@media (min-width: 1024px) {
  .card-container {
    width: calc(25% - 1rem);
  }
}

/* Card styles */
.card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.3s ease;
  height: 100%;
  backdrop-filter: blur(10px);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-color: rgba(168, 85, 247, 0.5);
}

.card img {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card:hover img {
  transform: scale(1.05);
}

/* Navigation buttons */
.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(168, 85, 247, 0.2);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
}

.carousel-button:hover {
  background: rgba(168, 85, 247, 0.4);
}

.carousel-button.prev {
  left: 1rem;
}

.carousel-button.next {
  right: 1rem;
}

@media (max-width: 640px) {
  .carousel-button {
    display: none;
  }
}

/* Stats grid responsiveness */
.stats-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding: 1rem;
}

/* Container padding adjustments */
.container {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 768px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}